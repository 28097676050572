<template>
  <div>
    <el-empty v-if="list.length == 0 ? true : false" description="暂无人员"></el-empty>
    <div v-for="(item, index) in list" :key="index" class="member-row">
      <img :src="item.avatar" alt="" class="avatar" />
      <div style="color: #484848; flex: 1; text-align: left">{{ item.userName }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  props: ["classRoomId"],
  mounted() {
    this.getListAction();
  },
  methods: {
    getListAction() {
      let that = this;
      this.$HTTP
        .httpToken({
          url: "/classroom/reserveRecord/getReserveRecordList",
          method: "post",
          data: {
            classRoomId: this.classRoomId,
          },
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            that.list = res.data;
          } else {
            that.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="scss">
.bg-class {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.member-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 10px 15px;
}
</style>
