<template>
  <div class="pcontent">
    <div class="pform" style="
                                        background-color: #ffffff;
                                        box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
                                        border-radius: 8px;
                                      ">
      <el-page-header style="
                                          width: 100%;
                                          height: 70px;
                                          display: flex;
                                          flex-direction: row;
                                          align-items: center;
                                          margin-left: 10px;
                                        " @back="back" content="课程详情">
      </el-page-header>
      <div style="width: 100%">
        <template v-if="empty">
          <a-row type="flex" justify="center" style="margin-bottom: 10px; margin-top: 10px; min-height: 400px">
            <el-empty :description="description"></el-empty>
          </a-row>
        </template>
        <div v-else v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)">
          <!--  class="pform--title" -->
          <div>
            <div style="margin-left:10px" class="pform--title--text">{{ info.name }}</div>
            <!-- class="pform--title--tags" -->
            <div style="display:flex;flex-wrap:wrap;margin-top:8px"
              v-if="info.courseTypeList && info.courseTypeList.length">
              <div style="margin-bottom:8px" class="pform--title--tags--text" v-for="(item, index) in info.courseTypeList"
                :key="index">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="pform--video">
            <!-- // 后台直接添加的视频 -->
            <template v-if="info.type > 2 || (info.runStatus < 0 && info.fileUrlList.length > 0)">
              <vueMiniPlayer ref="vueMiniPlayer" :video="video" :mutex="true" @ready="playerReady"
                @videoPlay="saveVideoProgressAction" @destroyed="saveVideoProgressAction" @ontimeupdate="ontimeupdate"
                style="border-radius: 8px" />
              <div class="pform--video--count" v-if="info.viewCount > 0">
                {{ info.viewCount }}人观看
              </div>
            </template>
            <template v-else-if="info.runStatus == 0">
              <el-image class="pform--video--thumb" :src="info.coverUrl" fit="cover"></el-image>
              <div class="pform--video--tip" @click="appointmentAction">
                {{ appointmentStateDes }}
              </div>
              <div class="pform--video--count" v-if="info.viewCount > 0" @click="dialogVisible = true">
                {{ info.reserveRecordCount }} 人预约
              </div>
            </template>
            <template v-else-if="info.runStatus == 1">
              <el-image class="pform--video--thumb" :src="info.coverUrl" fit="cover"></el-image>
              <div class="pform--video--tip" @click="liveAction">
                {{ liveStateDes }}
              </div>
              <div class="pform--video--count" v-if="info.viewCount > 0">
                {{ info.viewCount }} 观看
              </div>
            </template>
          </div>
          <div class="pform--user">
            <el-image class="pform--user--avatar"
              src="http://swlm.oss-cn-guiyang-gzdata-d01-a.res.gzdata.com.cn/servlet/file/1637047130291.png"
              fit="cover"></el-image>
            <div class="flex-col-start" style="flex: 1">
              <div class="pform--user--name">
                {{ info.nickName ? info.nickName : "" }}
              </div>
              <div class="pform--user--des">
                {{ info.companyName ? info.companyName : "" }}
              </div>
            </div>

            <a v-if="(info.runStatus != 1 && info.type != 3) || info.type == 3" :href="videoParams.url"
              download="1213.mp4" style="
                                                display: flex;
                                                flex-direction: row;
                                                justify-content: center;
                                                align-items: center;
                                                width: 100px;
                                                height: 40px;
                                                background-color: cornflowerblue;
                                                color: white;
                                                border-radius: 5px;
                                              ">
              <div>下载</div>
              <i class="el-icon-download"></i>
            </a>
          </div>
          <div class="pform--details">
            <div class="pform--details--title">课程介绍</div>
            <div class="pform--details--description">
              {{ info.desc }}
            </div>
            <div class="pform--details--from">
              {{ showFrom() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="已预约人员" :visible.sync="dialogVisible" :before-close="handleClose" :show-close="true"
      :destroy-on-close="true">
      <ResverMembersList :classRoomId="id"></ResverMembersList>
    </el-dialog>
  </div>
</template>

<script>
import { count } from "console";
import { mapState } from "vuex";
import ResverMembersList from "../../components/classroom-list/ResverMembersList.vue";
export default {
  name: "details",
  components: {
    ResverMembersList,
  },
  data() {
    return {
      isSpecialTopic: false,
      dialogVisible: false,
      id: "",
      videoParams: {
        id: "video",
        url: undefined,
        cover: "",
        muted: false,
        loop: false,
        autoplay: false,
        preload: "auto",
        poster: "",
        volume: 1,
        currentTime: 0,
        // crossOrigin: true,
      },
      currentTime: 0,
      info: {
        reserveRecord: false,
      },
      loading: true,
      count: 0,
      showMiniProPopup: false,
      empty: false,
      description: "暂无数据",
      learnProgress: 0,
      progressBg: "#37b6df", // 进度条背景色
      videoPlayFlag: false,
      classRoomId: "",
      duration: 0,
      timer2: null,
      videoFlag: false,
      specialSubjectId: "",
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
    video() {
      return this.videoParams;
    },
    appointmentStateDes() {
      if (this.info.userId === this.$store.getters.uid) {
        return "开始直播";
      } else {
        return this.info.reserveRecord ? "取消预约" : "立即预约";
      }
    },
    liveStateDes() {
      if (this.info.userId === this.$store.getters.uid) {
        return "开始直播";
      } else {
        return "观看直播";
      }
    },
  },
  created() {
    if (this.$route.query.specialTopic && this.$route.query.specialTopic === "true") {
      this.isSpecialTopic = true;
    }
    if (this.$route.query.specialSubjectId) {
      this.specialSubjectId = this.$route.query.specialSubjectId;
    }
  },

  activated() {
    this.id = this.$route.query.id;
    this.loading = true;
    this.empty = true;
    this.getCourseDetails();
  },
  mounted() { },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    appointmentAction() {
      if (!this.$store.getters.isLogin) {
        this.$elementConfirm("您当前未登录，请先登录！", "系统提示", {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: true,
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
        }).then(() => {
          this.$router.push("/login");
        });
        return;
      }
      if (this.info.userId === this.$store.getters.uid) {
        this.liveAction();
      } else {
        this.saveOrCancleResverAction();
      }
    },
    saveOrCancleResverAction() {
      let that = this;
      this.$HTTP
        .httpToken({
          url: this.info.reserveRecord
            ? "/classroom/reserveRecord/cancelReserveRecord"
            : "/classroom/reserveRecord/saveReserveRecord",
          method: "post",
          data: {
            classRoomId: this.id,
            type: this.info.type,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            that.info.reserveRecord = !that.info.reserveRecord;
            if (that.info.reserveRecord) {
              that.info.reserveRecordCount++;
            } else {
              that.info.reserveRecordCount--;
            }
            that.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "success",
            });
          } else {
            that.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((e) => { });
    },
    //播放器准备就绪设置进度
    playerReady() {
      let video = this.$refs.vueMiniPlayer.$video;
      if (video) {
        video.currentTime = this.currentTime;
      }
    },
    //更新进度该事件是video自带的，第三方封装没返回，所以第三方player放到项目内部，触发该事件
    ontimeupdate(event) {
      console.log('event :>> ', event);
      event.target.cancelBubble = true;
      //获取当前播放时间
      this.currentTime = event.target.currentTime;
      //获取总时长
      this.duration = event.target.duration;
      this.count++;
      //避免超频调用保存进度接口，这里10s调用一次
      if (this.count >= 20) {
        this.count = 0;
        this.saveVideoProgressAction();
      }
    },
    showFrom() {
      let from = this.info.resourceFrom ? this.info.resourceFrom : "";
      return "来源：" + from;
    },
    showName() {
      return this.info.companyName ? this.info.companyName : this.info.nickName;
    },
    liveAction() {
      if (!this.$store.getters.isLogin) {
        this.$elementConfirm("您当前未登录，请先登录！", "系统提示", {
          distinguishCancelAndClose: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: true,
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
        }).then(() => {
          this.$router.push("/login");
        });
        return;
      }
      // this.getIMRoomIdAction();
      this.$router.push({
        name: "ClassroomLive",
        query: {
          roomId: this.info.roomId,
          classRoomId: this.info.classRoomId,
          roomNumber: this.info.roomNumber,
          id: this.id,
          userId: this.info.userId,
          myUid: this.$store.getters.uid,
        },
      });
    },
    getIMRoomIdAction() {
      const loading = this.$loading.service({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$HTTP
        .httpToken({
          url: "/classroom/chatroom/getChatroomId",
          method: "post",
          data: {
            classRoomId: this.info.classRoomId,
          },
        })
        .then((res) => {
          loading.close();
          let chatroomId = res.data && res.data.chatroomId ? res.data.chatroomId : "";
          this.$router.push({
            name: "ClassroomLive",
            query: {
              roomId: this.info.roomId,
              classRoomId: this.info.classRoomId,
              roomNumber: this.info.roomNumber,
              id: this.id,
              userId: this.info.userId,
              myUid: this.$store.getters.uid,
              imRoomId: chatroomId,
            },
          });
        })
        .catch((e) => {
          loading.close();
        });
    },
    downLoadAction() {
      let link = this.videoParams.url;
      console.log(link);
      let date = new Date().getMilliseconds;
      let fileName = date + ".mp4";
      const a = document.createElement("a");
      document.body.append(a);
      a.style.display = "none";
      a.href = link;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 0);
    },
    //保存视频观看进度 没有登录不保存
    saveVideoProgressAction() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      this.$HTTP
        .httpToken({
          url: "/classroom/saveOrUpdateSpecialSubjectProgress",
          method: "post",
          data: {
            classRoomId: this.info.classRoomId,
            specialSubjectId: this.specialSubjectId ? this.specialSubjectId : "",
            learnedDuration: this.currentTime,
            duration: this.duration,
          },
        })
        .then((res) => { })
        .catch((e) => { });
    },
    back() {
      this.$router.back();
    },
    //获取课程详情
    getCourseDetails() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: this.$store.getters.isLogin
            ? "/classroom/getCloudClassRoomInfoById"
            : "/classroom/getCloudClassRoomInfoByIdNoCheck",
          method: "post",
          data: {
            classRoomId: this.id,
            specialSubjectId: this.specialSubjectId,
          },
        })
        .then((res) => {
          console.log("单个课程", res);
          this.loading = false;
          if (res.code == 0 && res.data) {
            this.empty = false;
            this.info = res.data;
            this.currentTime = this.info.learnedDuration;
            if (this.info.type > 2) {
              this.videoParams.cover = this.info.coverUrl;
              this.videoParams.url = this.info.videoUrl;
            } else {
              if (this.info.fileUrlList && this.info.fileUrlList.length > 0) {
                this.videoParams.cover = this.info.fileCoverUrlList[0];
                this.videoParams.url = this.info.fileUrlList[0];
              }
            }
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.pcontent {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pform {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &--video {
    width: calc(100% - 20px);
    margin: 0 10px;
    margin-top: 10px;
    //height: calc(100% * 0.1);
    aspect-ratio: 16/9;
    border-radius: 8px;
    background: #000000;
    position: relative;

    &--count {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0 10px;
      height: 27px;
      line-height: 27px;
      border-radius: 8px;
      background-color: rgba(64, 64, 64, 42);
      text-align: center;
      color: #ffffff;
      text-align: center;
    }

    &--thumb {
      width: 100%;
      height: 100%;
      // height: 500px;
      border-radius: 8px;
    }

    &--tip {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 50px);
      width: 100px;
      height: 40px;
      line-height: 40px;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      text-align: center;
      color: #42b983;
      cursor: pointer;
      border: 1px solid #42b983;
    }
  }

  &--title {
    width: 100%;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;

    &--text {
      font-size: 20px;
      color: #000000;
      font-weight: 700;
      text-align: left;
      flex: 1;
      margin-right: 10px;
    }

    &--tags {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &--text {
        border-radius: 4px;
        margin-left: 10px;
        padding: 4px 10px;
        background: #ddf6ff;
        color: #0e9bd2;
        border: 1px solid #0e9bd2;
      }
    }
  }

  &--user {
    height: 60px;
    width: 100%;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(240, 240, 240, 100);

    &--avatar {
      width: 40px;
      height: 40px;
    }

    &--des {
      margin-left: 10px;
      color: #a5a7b2;
      font-size: 14px;
      text-align: left;
      flex: 1;
    }

    &--name {
      margin-left: 10px;
      color: #333333;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      flex: 1;
    }

    &--time {
      margin-left: 10px;
      color: #aeaeae;
      flex: 1;
      text-align: left;
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;

    &--title {
      width: 100%;
      height: 50px;
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      line-height: 50px;
      text-align: left;
      padding: 0 10px;
      box-sizing: border-box;
    }

    &--description {
      text-align: left;
      padding: 0 10px;
    }

    &--from {
      color: #a5a7b2;
      font-size: 12px;
      text-align: right;
      padding: 0 10px;
    }
  }
}

.mimipro {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--title {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
    color: #000;
  }

  &--tips {
    color: #000;
    font-size: 16px;
  }

  &--qrcode {
    width: 70%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 800px) {
  .pform {
    width: 100%;
  }
}

@media screen and (min-width: 800px) {
  .pform {
    width: 70%;
  }
}

::v-deep .el-progress-bar__outer,
::v-deep .el-progress-bar__inner {
  border-radius: 8px;
}

::v-deep .el-progress-bar__outer {
  background: #3e8ccd;
}

// ::v-deep ._progress{
//   pointer-events: none!important;
// }

::v-deep .pointer {
  pointer-events: none !important;
}
</style>
